/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M14 14a7 7 0 110-14 7 7 0 010 14zm0-2.546a4.456 4.456 0 001.705-8.57A4.455 4.455 0 1014 11.455zm13.247 9.014c.223.485.38 1.184.516 2.111.094.65.167 1.304.22 1.959l.014.19.003.09A3.182 3.182 0 0124.822 28H3.178A3.182 3.182 0 010 24.818l.003-.09.015-.19c.054-.655.127-1.308.22-1.959.136-.927.292-1.626.515-2.111C1.73 18.345 8.93 15.273 14 15.273c5.069 0 12.27 3.072 13.247 5.195zm-2.442.97a6.549 6.549 0 00-.747-.546c-.705-.453-1.637-.938-2.666-1.377-2.466-1.053-5.112-1.697-7.392-1.697-2.28 0-4.925.644-7.392 1.697-1.029.439-1.96.924-2.666 1.377-.314.203-.57.39-.747.546a1.95 1.95 0 00-.151.146c-.086.225-.194.726-.288 1.365-.063.426-.116.887-.16 1.35-.024.237-.04.43-.05.553a.636.636 0 00.632.602h21.644a.636.636 0 00.632-.602c-.01-.123-.026-.316-.05-.553a27.93 27.93 0 00-.16-1.35c-.094-.64-.202-1.14-.288-1.366a1.82 1.82 0 00-.15-.145z" _fill="#fff" fill-opacity=".1"/>'
  }
})
